<template>
    <BT-Blade-Item
        bladeName="journey-archive"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="journey-archives"
        title="Journey Archive">
        <template v-slot="{ item }">
            <BT-Field-String
                v-model="item.journeyName"
                label="Journey Name" />
            
            <BT-Field-Date
                label="Started On"
                shortDateAndTime
                v-model="item.startedOn" />

            <BT-Field-Date
                label="Finished On"
                shortDateAndTime
                v-model="item.endedOn" />
            
            <BT-Field-Entity
                label="Driver"
                navigation="users"
                :itemValue="item.driverUserID"
                itemText="userName"
                alternateText="Unknown" />

            <v-expansion-panels v-model="panelV" multiple>
                <BT-Blade-Expansion-Items
                    addBladeName="delivery-archive"
                    :canExportCSV="false"
                    :headers="[
                        { text: 'Time', value: 'deliveredOn', display: true },
                        { text: 'Customer', value: 'buyerID', display: true },
                        { text: 'Address', value: 'destinationLocationID', display: true },
                        { text: 'Temp', value: 'tempOnDelivery' }
                    ]"
                    hideActions
                    navigation="delivery-archives"
                    :onPullSuccessAsync="pullDeliveries"
                    :params="{ includeDetails: true, journeyID: item.id }"
                    :showList="showMax"
                    title="Movements">
                    <template v-slot:actions>
                        <v-btn @click.stop="showMax = !showMax" text class="my-auto py-auto">{{ showMax ? 'Less' : 'More' }}</v-btn>
                    </template>
                    <template v-slot:deliveredOn="{ item }">
                        {{ item.deliveredOn | toTime }}
                    </template>
                    <template v-slot:buyerID="{ item }">
                        <BT-Entity
                            :itemValue="item.buyerID"
                            navigation="public-companies"
                            single
                            itemText="companyName"
                            useLocalCache />
                    </template>
                    <template v-slot:destinationLocationID="{ item }">
                        <BT-Entity
                            :itemValue="item.destinationLocationID"
                            navigation="public-locations"
                            single
                            textFilter="toFamiliarLocationLine"
                            useLocalCache />
                    </template>
                    <template v-slot:listItem="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>Released</v-list-item-title>
                            <v-list-item-subtitle>
                                <BT-Entity
                                    navigation="public-locations"
                                    :itemValue="item.departureLocationID"
                                    single
                                    textFilter="toLocationLine"
                                    useLocalCache>
                                    <template v-slot:prepend>
                                        <v-icon small left>mdi-map-marker-up</v-icon>
                                    </template>
                                </BT-Entity>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon small left>mdi-clock-outline</v-icon>{{ item.releasedOn | toShortDateAndTime }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Entity
                                    navigation="users"
                                    alternateText="Unknown"
                                    itemText="userName"
                                    :itemValue="item.releasedByUserID">
                                    <template v-slot:prepend><v-icon small left>mdi-account-arrow-right-outline</v-icon>By</template>
                                </BT-Entity>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Signature-Overlay
                                    v-model="item.proofOfRelease"
                                    :canEdit="false"
                                    color="white"
                                    text="Proof Of Release" />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="item.isPickup">
                            <v-list-item-title>*Picked Up</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <v-list-item-title>Delivered</v-list-item-title>
                            <v-list-item-subtitle>
                                <BT-Entity
                                    navigation="public-locations"
                                    :itemValue="item.destinationLocationID"
                                    single
                                    textFilter="toLocationLine"
                                    useLocalCache>
                                    <template v-slot:prepend>
                                        <v-icon small left>mdi-map-marker-down</v-icon>
                                    </template>
                                </BT-Entity>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon small left>mdi-clock-outline</v-icon>{{ item.deliveredOn | toShortDateAndTime }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Entity
                                    navigation="users"
                                    alternateText="Unknown"
                                    itemText="userName"
                                    :itemValue="item.deliveredByUserID">
                                    <template v-slot:prepend><v-icon small left>mdi-account-arrow-right-outline</v-icon>By</template>
                                </BT-Entity>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Signature-Overlay
                                    v-model="item.proofOfDelivery"
                                    :canEdit="false"
                                    color="white"
                                    text="Proof Of Delivery" />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Journey-Archive',
    components: {
        BTSignatureOverlay: () => import('~components/BT-Signature-Overlay.vue'),
    },
    data: function() {
        return {
            panelV: [0],
            showMax: false
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        pullDeliveries(list) {
            var r = list.sort(firstBy(x => x.deliveredOn));
            return r;
        },
    }
}
</script>